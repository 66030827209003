
class Calendar
{
	constructor ()
	{
		this.$container = $('#calendar');
		this.$carousel = this.$container.find('div.event_list');
		this.$navBtns = this.$container.find('button.btn_next, button.btn_prev');
		this.$btnPrev = this.$navBtns.filter('.btn_prev');
		this.$btnNext = this.$navBtns.filter('.btn_next');

		this.bindEvents();
		this.$carousel.owlCarousel({
			items: 1
		});

	}

	bindEvents ()
	{
		this.$carousel.on('changed.owl.carousel', this.onChange.bind(this));
		this.$navBtns.on('click', this.nav.bind(this));
	}

	nav ( _e )
	{
		let $btn = $( _e.target );
		let strDir = $btn.attr('data-dir');
		this.$carousel.trigger( strDir + '.owl.carousel');
	}

	onChange ( _e )
	{
		this.$navBtns.removeClass('is-disabled');
		if ( _e.item.index == 0 )
		{
			this.$btnPrev.addClass('is-disabled');
		}
		else if ( _e.item.index == (_e.item.count - 1) )
		{
			this.$btnNext.addClass('is-disabled');
		}
	}
}

export {Calendar};