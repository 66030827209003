import {Calendar} from './Calendar.js';


class Main
{
	constructor ()
	{
		new Calendar();
		this.$nav = $('body').find('> header > nav');
		this.bindEvents();

		if (!Modernizr.picture)
		{
			this.setIE();
		}

	}

	bindEvents ()
	{
		$('#btn_open_menu').on('click', this.toggleMenu.bind(this));
	}

	toggleMenu ()
	{
		this.$nav.toggleClass('is-visible');
	}

	setIE ()
	{
		document.createElement( "picture" );
		$.getScript('/_/js/picturefill.min.js');
	}

}

$(function () {
	new Main();
});